.cus-width{
    width: 45%;
}
@media (width < 768px){
    .cus-width{
        width: 90%;
    }
}
@media (min-width: 992px) {
    .navbar-nav .dropdown-menu-center {
        transform: translateX(-75%);
    }
}