.kifmis-logo {
    border-Radius: 50px;
    height: 100px;
    width: 100px;
    margin-top: -120px;
}

@media screen and (max-width: 992px) {
    .kifmis-logo {
        margin-top: -60px;
    }
}
